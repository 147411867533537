exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/aboutUs.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-archive-highlights-details-js": () => import("./../../../src/templates/archiveHighlightsDetails.js" /* webpackChunkName: "component---src-templates-archive-highlights-details-js" */),
  "component---src-templates-archive-js": () => import("./../../../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-archive-tutorials-details-js": () => import("./../../../src/templates/archiveTutorialsDetails.js" /* webpackChunkName: "component---src-templates-archive-tutorials-details-js" */),
  "component---src-templates-calendar-js": () => import("./../../../src/templates/calendar.js" /* webpackChunkName: "component---src-templates-calendar-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contactUs.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-lab-facilities-details-js": () => import("./../../../src/templates/labFacilitiesDetails.js" /* webpackChunkName: "component---src-templates-lab-facilities-details-js" */),
  "component---src-templates-lab-js": () => import("./../../../src/templates/lab.js" /* webpackChunkName: "component---src-templates-lab-js" */),
  "component---src-templates-lab-tutorials-details-js": () => import("./../../../src/templates/labTutorialsDetails.js" /* webpackChunkName: "component---src-templates-lab-tutorials-details-js" */),
  "component---src-templates-legal-disclaimer-js": () => import("./../../../src/templates/legalDisclaimer.js" /* webpackChunkName: "component---src-templates-legal-disclaimer-js" */),
  "component---src-templates-library-highlights-details-js": () => import("./../../../src/templates/libraryHighlightsDetails.js" /* webpackChunkName: "component---src-templates-library-highlights-details-js" */),
  "component---src-templates-library-js": () => import("./../../../src/templates/library.js" /* webpackChunkName: "component---src-templates-library-js" */),
  "component---src-templates-opening-details-js": () => import("./../../../src/templates/openingDetails.js" /* webpackChunkName: "component---src-templates-opening-details-js" */),
  "component---src-templates-our-collection-details-js": () => import("./../../../src/templates/ourCollectionDetails.js" /* webpackChunkName: "component---src-templates-our-collection-details-js" */),
  "component---src-templates-policies-js": () => import("./../../../src/templates/policies.js" /* webpackChunkName: "component---src-templates-policies-js" */),
  "component---src-templates-press-details-js": () => import("./../../../src/templates/pressDetails.js" /* webpackChunkName: "component---src-templates-press-details-js" */),
  "component---src-templates-press-js": () => import("./../../../src/templates/press.js" /* webpackChunkName: "component---src-templates-press-js" */),
  "component---src-templates-programme-details-js": () => import("./../../../src/templates/programmeDetails.js" /* webpackChunkName: "component---src-templates-programme-details-js" */),
  "component---src-templates-programmes-js": () => import("./../../../src/templates/programmes.js" /* webpackChunkName: "component---src-templates-programmes-js" */),
  "component---src-templates-servicedetails-js": () => import("./../../../src/templates/servicedetails.js" /* webpackChunkName: "component---src-templates-servicedetails-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-work-with-us-js": () => import("./../../../src/templates/workWithUs.js" /* webpackChunkName: "component---src-templates-work-with-us-js" */),
  "component---src-templates-workshop-details-js": () => import("./../../../src/templates/workshopDetails.js" /* webpackChunkName: "component---src-templates-workshop-details-js" */),
  "component---src-templates-workshops-js": () => import("./../../../src/templates/workshops.js" /* webpackChunkName: "component---src-templates-workshops-js" */)
}

